<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="job"
            rules="required"
          >
            <b-form-group
              label="job"
              label-for="job"
            >
              <b-form-select
                id="job"
                v-model="assignEngineerForm.job"
                :state="getValidationState(validationContext)"
                :options="optionJob"
                trim
                placeholder="job"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="loading"
          md="6"
          class="d-flex align-items-center justify-content-center"
        >
          <!-- Loading Indicator -->
          <b-spinner label="Loading..." />
        </b-col>
        <b-col
          v-if="!loading && optionUser.length > 0"
          md="6"
        >
          <!-- Engineer -->
          <validation-provider
            #default="validationContext"
            name="engineer"
            rules="required"
          >
            <b-form-group
              label="engineer"
              label-for="engineer"
            >
              <b-form-select
                id="engineer"
                v-model="assignEngineerForm.user_id"
                :state="getValidationState(validationContext)"
                :options="optionUser"
                trim
                placeholder="Select an engineer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="assignEngineer()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="assignEngineer()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {},
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      optionUser: [],
      optionJob: [
        { value: 'application reviewer', text: 'Application Reviewer' },
        { value: 'first reviewer', text: 'First Reviewer' },
        { value: 'second reviewer', text: 'Second Reviewer' },
      ],
      loader: false,
      validationErrors: {},
      errors: {},
      loading: false,

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const assignEngineerForm = ref({})
    return {
      getValidationState,
      assignEngineerForm,
    }
  },
  // mounted() {
  //   this.showEngineer()
  // },
  watch: {
    'assignEngineerForm.job': function (newJob) {
      if (newJob) {
        this.fetchEngineersByJob(newJob)
      }
    },
  },

  methods: {
    fetchEngineersByJob(job) {
      this.loading = true
      const url = `engineer/${this.$route.params.id}/job-filter?job=${job}`
      axios.get(url)
        .then(res => {
          this.optionUser = decryptData(res.data.payload).data.engineers.map(item => ({
            value: item.id,
            text: item.name,
          }))
        })
        .catch(error => {
          this.errors = error.response.data.errors || {}
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    assignEngineer() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        // formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.assignEngineerForm) {
          formData.append(key, this.assignEngineerForm[key])
        }

        axios
          .post(`requests/${this.$route.params.id}/assign`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'requests' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
